import "core-js/modules/es.string.trim.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import $ from "jquery";
import "@a/js/particles.min.js";
export default {
  data: function data() {
    return {
      loginStatus: false,
      loginButtonTip: '登录',
      loginMsg: '',
      userNameMsg: '',
      passwordMsg: ''
    };
  },
  mounted: function mounted() {
    var this_ = this;
    this.clearErrorMsg();
    localStorage.setItem('stationId', 2);
    localStorage.setItem('openNum', '/monitoring/home');
    particlesJS('particles-js', {
      "particles": {
        "number": {
          "value": 40,
          "density": {
            "enable": true,
            "value_area": 800
          }
        },
        "color": {
          "value": "#ffffff"
        },
        "shape": {
          "type": "circle",
          "stroke": {
            "width": 0,
            "color": "#000000"
          },
          "polygon": {
            "nb_sides": 5
          },
          "image": {
            "src": "img/github.svg",
            "width": 100,
            "height": 100
          }
        },
        "opacity": {
          "value": 0.5,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 1,
            "opacity_min": 0.1,
            "sync": false
          }
        },
        "size": {
          "value": 5,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 40,
            "size_min": 0.1,
            "sync": false
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 150,
          "color": "#ffffff",
          "opacity": 0.4,
          "width": 1
        },
        "move": {
          "enable": true,
          "speed": 3,
          "direction": "none",
          "random": false,
          "straight": false,
          "out_mode": "out",
          "attract": {
            "enable": false,
            "rotateX": 600,
            "rotateY": 1200
          }
        }
      },
      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": true,
            "mode": "grab"
          },
          "onclick": {
            "enable": true,
            "mode": "push"
          },
          "resize": true
        },
        "modes": {
          "grab": {
            "distance": 400,
            "line_linked": {
              "opacity": 1
            }
          },
          "bubble": {
            "distance": 400,
            "size": 40,
            "duration": 2,
            "opacity": 8,
            "speed": 3
          },
          "repulse": {
            "distance": 200
          },
          "push": {
            "particles_nb": 4
          },
          "remove": {
            "particles_nb": 2
          }
        }
      },
      "retina_detect": true,
      "config_demo": {
        "hide_card": false,
        "background_color": "#b61924",
        "background_image": "",
        "background_position": "50% 50%",
        "background_repeat": "no-repeat",
        "background_size": "cover"
      }
    });
    var h = window.innerHeight; //		var w = window.innerWidth;

    $(".boardLogin").css("margin-top", (h - 80) / 2 + "px"); //		$(".boardLogin").css("margin-left", w * 0.4 + "px");

    window.addEventListener('resize', function () {
      var h = window.innerHeight;
      $(".boardLogin").css("margin-top", (h - 80) / 2 + "px"); //			var w = window.innerWidth;
      //			$(".boardLogin").css("margin-left", w * 0.4 + "px");
    });
    var pw = $("#password");
    var un = $("#userName");
    var pwName = pw.attr("Name");
    var unName = un.attr("Name");
    setTimeout(this.setName(pw, un, unName, pwName), 10);
    setTimeout(function () {
      $("#password").attr("type", "password");
    }, 1000);
  },
  methods: {
    setName: function setName(pw, un, unName, pwName) {
      pw.attr("Name", pwName);
      un.attr("Name", unName);
    },
    validite: function validite(type) {
      this.clearErrorMsg();
      var userName = $("#userName").val().trim();
      var password = $("#password").val().trim();

      if (type == 0) {
        if (userName.length < 1) {
          this.userNameMsg = '用户名不能为空';
        } else {
          this.userNameMsg = '';
        }

        if (password.length < 1) {
          this.passwordMsg = '密码不能为空';
        } else {
          this.passwordMsg = '';
        }
      } else if (type == 1) {
        if (userName.length < 1) {
          this.userNameMsg = '用户名不能为空';
        } else {
          this.userNameMsg = '';
        }
      } else if (type == 2) {
        if (password.length < 1) {
          this.passwordMsg = '密码不能为空';
        } else {
          this.passwordMsg = '';
        }
      }
    },
    clearErrorMsg: function clearErrorMsg() {
      this.$store.commit('setErrorMsg', '');
    },
    login: function login() {
      if (this.loginStatus) {
        return;
      }

      this.validite(0);

      if (this.userNameMsg.length < 1 && this.passwordMsg.length < 1) {
        this.loginButtonTip = this.loginStatus ? '登录' : '登录中...';
        this.$store.dispatch('users/userLogin', {
          user_name: $("#userName").val().trim(),
          user_password: $("#password").val().trim(),
          router: this.$router,
          me: this
        });
      }
    },
    checkLogin: function checkLogin(event) {
      if (event.keyCode == 13) {
        this.login();
      }
    },
    register: function register() {
      this.$router.push({
        path: '/register'
      });
    }
  },
  watch: {
    "$store.state.errorMsg": {
      handler: function handler(newVal, oldVal) {
        this.loginMsg = newVal;
      }
    },
    "$store.state.loginStatus": {
      handler: function handler(newVal, oldVal) {
        this.loginStatus = newVal;
        this.loginButtonTip = this.loginStatus ? '登录中...' : '登录';
      }
    }
  }
};
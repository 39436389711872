<template>
	<div class="body">
		<a-row justify="space-around" align="middle">
			<a-col :span="9">
				<div>
					<a-card title="运行信息" :bordered="false" style="min-width: 300px" :headStyle='headStyle' :bodyStyle='bodyStyle'>
						<a-row type="flex" justify="space-around" align="middle" :gutter="[0,0]">
							<a-col :span="8">
								<div>
									<a-card :bordered="false" style="width: 100%;">
										<img width="48px" src="@a/images/icon/ic_zhaungji.png" />
										<div class="name">
											<p>装机容量(kWh)</p>
										</div>
										<div class="data">
											<p>{{capacity}}</p>
										</div>
									</a-card>
								</div>
							</a-col>
							<a-col :span="8">
								<div>
									<a-card :bordered="false" style="width: 100%;">
										<img width="48px" src="@a/images/icon/ic_settingdate.png" />
										<div class="name">
											<p>投运日期</p>
										</div>
										<div class="data">
											<p>{{enableOperationDate}}</p>
										</div>
									</a-card>
								</div>
							</a-col>
							<a-col :span="8">
								<div>
									<a-card :bordered="false" style="width: 100%;">
										<img width="48px" src="@a/images/icon/ic_home_power.png" />
										<div class="name">
											<p>实时功率(kW)</p>
										</div>
										<div class="data">
											<p>{{power}}</p>
										</div>
									</a-card>
								</div>
							</a-col>
						</a-row>
						<a-row type="flex" justify="space-around" align="middle" :gutter="[0,0]">
							<a-col :span="8">
								<div>
									<a-card :bordered="false" style="width: 100%;">
										<img width="48px" src="@a/images/icon/ic_eblock.png" />
										<div class="name">
											<p>在线能量块</p>
										</div>
										<div class="data">
											<p>{{eblockOnLineCount}}/{{eblockTotalCount}}</p>
										</div>
									</a-card>
								</div>
							</a-col>
							<a-col :span="8">
								<div>
									<a-card :bordered="false" style="width: 100%;">
										<img width="48px" src="@a/images/icon/ic_elink.png" />
										<div class="name">
											<p>在线能量链</p>
										</div>
										<div class="data">
											<p>{{eLinkOnlineCount}}/{{eLinkTotalCount}}</p>
										</div>
									</a-card>
								</div>
							</a-col>
							<a-col :span="8">
								<div>
									<a-card :bordered="false" style="width: 100%;">
										<img width="48px" src="@a/images/icon/ic_workdate.png" />
										<div class="name">
											<p>投运时间(天)</p>
										</div>
										<div class="data">
											<p>{{operationTime}}</p>
										</div>
									</a-card>
								</div>
							</a-col>
						</a-row>
					</a-card>
				</div>
			</a-col>
			<a-col :span="9">
				<div>
					<a-card title="统计信息" :bordered="false" style="min-width: 300px" :headStyle='headStyle' :bodyStyle='bodyStyle'>
						<a-row type="flex" justify="space-around" align="middle" :gutter="[16,16]">
							<a-col :span="6">
								<div>
									<a-card :bordered="false" style="width: 100%;">
										<img width="48px" src="@a/images/icon/ic_income_month_p.png" />
										<div class="name">
											<p>今日收益(元)</p>
										</div>
										<div class="data">
											<p>{{income}}</p>
										</div>
									</a-card>
								</div>
							</a-col>
							<a-col :span="6">
								<div>
									<a-card :bordered="false" style="width: 100%;">
										<img width="48px" src="@a/images/icon/ic_income_all_p.png" />
										<div class="name">
											<p>累计收益(元)</p>
										</div>
										<div class="data">
											<p>{{accumulativeIncome}}</p>
										</div>
									</a-card>
								</div>
							</a-col>
							<a-col :span="6">
								<div>
									<a-card :bordered="false" style="width: 100%;">
										<img width="48px" src="@a/images/icon/ic_income_all_p.png" />
										<div class="name">
											<p>系统效率(%)</p>
										</div>
										<div class="data">
											<p>{{systemEfficiency}}</p>
										</div>
									</a-card>
								</div>
							</a-col>
						</a-row>
						<a-row type="flex" justify="space-around" align="middle" :gutter="[16]">
							<a-col :span="6">
								<div>
									<a-card :bordered="false" style="width: 100%;">
										<img width="48px" src="@a/images/icon/ic_charge_month_p.png" />
										<div class="name">
											<p>今日放电(kWh)</p>
										</div>
										<div class="data">
											<p>{{dischargeAmount}}</p>
										</div>
									</a-card>
								</div>
							</a-col>
							<a-col :span="6">
								<div>
									<a-card :bordered="false" style="width: 100%;">
										<img width="48px" src="@a/images/icon/ic_charge_all_p.png" />
										<div class="name">
											<p>累计放电(kWh)</p>
										</div>
										<div class="data">
											<p>{{accumulativeDischargeAmount}}</p>
										</div>
									</a-card>
								</div>
							</a-col>
							<a-col :span="6">
								<div>
									<a-card :bordered="false" style="width: 100%;">
										<img width="48px" src="@a/images/icon/ic_charge_all_p.png" />
										<div class="name">
											<p>累计充电(kWh)</p>
										</div>
										<div class="data">
											<p>{{accumulativeChargeAmount}}</p>
										</div>
									</a-card>
								</div>
							</a-col>
						</a-row>
					</a-card>
				</div>
			</a-col>
			<a-col :span="6">
				<div>
					<a-card title="剩余电量" :bordered="false" style="min-width: 300px" :headStyle='headStyle' :bodyStyle='bodyStyle'>
						<div style="height: 220px;">
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style="display: none;">
								<symbol id="wave">
									<path d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"></path>
									<path d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"></path>
									<path d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"></path>
									<path d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"></path>
								</symbol>
							</svg>
							<div class="box">
								<div class="percent">
									<div class="percentNum" id="count">{{0}}</div>
									<div class="percentB">kWh</div>
								</div>
								<div id="water" class="water">
									<svg viewBox="0 0 560 20" class="water_wave water_wave_back">
										<use xlink:href="#wave"></use>
									</svg>
									<svg viewBox="0 0 560 20" class="water_wave water_wave_front">
										<use xlink:href="#wave"></use>
									</svg>
								</div>
							</div>
						</div>
					</a-card>
				</div>
			</a-col>
		</a-row>
		<a-row type="flex" justify="space-between" align="bottom" style="width: 100%; margin-top: 10px;">
			<div style="width: 100%;">
				<div style="width: 100%; min-height: 510px; height: 100%; margin-top 40px; float: left; color: #B7B8BC;" id="parent">
					<div class="bus-barname">{{diagramData.name}}</div>
					<div class="big-branch-line-35" style="width: 100%; margin: auto;">
					</div>
					<div class="childrens">
						<div class="model" v-for="(a, index_y) in diagramData.childrenModels" :key="index_y + '--4'">
							<div class="branch_line_out-10-load-0" :id="'meter-' + a.meterId">
								<div class="break" v-show="a.breakUpper">
								</div>
								<div class="transformer" v-show="a.transformer">
								</div>
								<div style="margin: auto;" v-show="a.meter">
									<div class="meter">
										<div class="meterData" :id="'meter-P' + a.meterId">
										</div>
										<div class="meterData" :id="'meter-V' + a.meterId">
										</div>
										<div class="meterData" :id="'meter-I' + a.meterId">
										</div>
									</div>
								</div>
								<div class="break" style="margin-bottom: 10px;" v-show="a.breakDown">
								</div>
							</div>
							<div class="mode-name">
								<div>
									<img src="@a/images/system-diagram/img_fuzai.png" v-show="a.type == 5" />
									<img src="@a/images/system-diagram/img_eblock.png" v-show="a.type == 2" />
								</div>
								<div class="mode-name">
									{{a.name}}
								</div>
							</div>
						</div>
						<div class="model-bus-bar" v-for="(x, index_x) in diagramData.childrenBusBars" :key="index_x + '-1'">
							<div class="branch_line_out-35-10" :id="'meter-' + x.meterId">
								<div class="break" v-show="x.breakUpper">
								</div>
								<div class="transformer" v-show="x.transformer">
								</div>
								<div style="margin: auto;" v-show="x.meter">
									<div class="meter">
										<div class="meterData" :id="'meter-P' + x.meterId">
										</div>
										<div class="meterData" :id="'meter-V' + x.meterId">
										</div>
										<div class="meterData" :id="'meter-I' + x.meterId">
										</div>
									</div>
								</div>
								<div class="break" style="margin-bottom: 10px;" v-show="x.breakDown">
								</div>
							</div>
							<div class="bus-bar">
								<div class="bus-barname">{{x.name}} </div>
								<div class="big-branch-line-35">
								</div>
							</div>
							<div class="childrens">
								<div class="model" v-for="(y, index_y) in x.childrenModels" :key="index_y + '--2'">
									<div class="branch_line_out-10-load-1" :id="'meter-' + y.meterId" :style="{'height':(y.height + 'px')}">
										<div class="break" v-show="y.breakUpper">
										</div>
										<div class="transformer" v-show="y.transformer">
										</div>
										<div style="margin: auto;" v-show="y.meter">
											<div class="meter">
												<div class="meterData" :id="'meter-P' + y.meterId">
												</div>
												<div class="meterData" :id="'meter-V' + y.meterId">
												</div>
												<div class="meterData" :id="'meter-I' + y.meterId">
												</div>
											</div>
										</div>
										<div class="break" style="margin-bottom: 10px;" v-show="y.breakDown">
										</div>
									</div>
									<div class="mode-name">
										<div>
											<img src="@a/images/system-diagram/img_fuzai.png" v-show="y.type == 5" />
											<img src="@a/images/system-diagram/img_eblock.png" v-show="y.type == 2" />
										</div>
										<div class="mode-name">
											{{y.name}}
										</div>
									</div>
								</div>
								<div class="model-bus-bar" v-for="(y, index_y) in x.childrenBusBars" :key="index_y + '-2'">
									<div class="branch_line_out-35-10" :id="'meter-' + y.meterId">
										<div class="break" v-show="y.breakUpper">
										</div>
										<div class="transformer" v-show="y.transformer">
										</div>
										<div style="margin: auto;" v-show="y.meter">
											<div class="meter">
												<div class="meterData" :id="'meter-P' + y.meterId">
												</div>
												<div class="meterData" :id="'meter-V' + y.meterId">
												</div>
												<div class="meterData" :id="'meter-I' + y.meterId">
												</div>
											</div>
										</div>
										<div class="break" style="margin-bottom: 10px;" v-show="y.breakDown">
										</div>
									</div>
									<div class="bus-bar">
										<div class="bus-barname">{{y.name}} </div>
										<div class="big-branch-line-35">
										</div>
									</div>
									<div class="childrens">
										<div class="model" v-for="(z, index_y) in y.childrenModels" :key="index_y + '--3'">
											<div class="branch_line_out-10-load-2" :id="'meter-' + z.meterId">
												<div class="break" v-show="z.breakUpper">
												</div>
												<div class="transformer" v-show="z.transformer">
												</div>
												<div style="margin: auto; overflow: visible;" v-show="z.meter">
													<div class="meter">
														<div class="meterData" :id="'meter-P' + z.meterId">
														</div>
														<div class="meterData" :id="'meter-V' + z.meterId">
														</div>
														<div class="meterData" :id="'meter-I' + z.meterId">
														</div>
													</div>
												</div>
												<div class="break" style="margin-bottom: 10px;" v-show="z.breakDown">
												</div>
											</div>
											<div class="mode-name">
												<div>
													<img src="@a/images/system-diagram/img_fuzai.png" v-show="z.type == 5" />
													<img src="@a/images/system-diagram/img_eblock.png" v-show="z.type == 2" />
												</div>
												<div class="mode-name">
													{{z.name}}
												</div>
											</div>
										</div>
										<div class="model-bus-bar" v-for="(z, index_y) in y.childrenBusBars" :key="index_y + '-3'">
											<div class="branch_line_out-35-10" :id="'meter-' + z.meterId">
												<div class="break" v-show="z.breakUpper">
												</div>
												<div class="transformer" v-show="z.transformer">
												</div>
												<div style="margin: auto;" v-show="z.meter">
													<div class="meter">
														<div class="meterData" :id="'meter-P' + z.meterId">
														</div>
														<div class="meterData" :id="'meter-V' + z.meterId">
														</div>
														<div class="meterData" :id="'meter-I' + z.meterId">
														</div>
													</div>
												</div>
												<div class="break" style="margin-bottom: 10px;" v-show="z.breakDown">
												</div>
											</div>
											<div class="bus-bar">
												<div class="bus-barname">{{z.name}} </div>
												<div class="big-branch-line-35">
												</div>
											</div>
											<div class="childrens">
												<div class="model" v-for="(l, index_y) in z.childrenModels" :key="index_y + '--4'">
													<div class="branch_line_out-10-load-3" :id="'meter-' + l.meterId">
														<div class="break" v-show="l.breakUpper">
														</div>
														<div class="transformer" v-show="l.transformer">
														</div>
														<div style="margin: auto;" v-show="l.meter">
															<div class="meter">
																<div class="meterData" :id="'meter-P' + l.meterId">
																</div>
																<div class="meterData" :id="'meter-V' + l.meterId">
																</div>
																<div class="meterData" :id="'meter-I' + l.meterId">
																</div>
															</div>
														</div>
														<div class="break" style="margin-bottom: 10px;" v-show="l.breakDown">
														</div>
													</div>
													<div class="mode-name">
														<div>
															<img src="@a/images/system-diagram/img_fuzai.png" v-show="l.type == 5" />
															<img src="@a/images/system-diagram/img_eblock.png" v-show="l.type == 2" />
														</div>
														<div class="mode-name">
															{{l.name}}
														</div>
													</div>
												</div>
												<div class="model-bus-bar" v-for="(l, index_y) in z.childrenBusBars" :key="index_y + '-4'">
													<div class="branch_line_out-35-10">
														<div class="branch_line_inner 35-10">
														</div>
													</div>
													<div class="bus-bar">
														<div class="bus-barname">{{l.name}} </div>
														<div class="big-branch-line-35">
														</div>
													</div>
													<div>

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a-row>
	</div>
</template>

<script>
	import axios from "axios";
	import $ from "jquery";
	export default {
		data() {
			return {
				diagramData: [],
				meterData: [],
				charge_list: [],
				discharge_list: [],
				stand_still_list: [],
				timer_system_diagram: null,
				timer_station_home_data: null,
				normalAlarm: 0,
				importantAlarm: 0,
				urgentAlarm: 0,
				alarmChart: null,
				alarmOption: null,
				accumulativeDischargeAmount: 0,
				accumulativeChargeAmount: 0,
				dischargeAmount: 0,
				chargeAmount: 0,
				systemEfficiency: 0,
				income: 0,
				accumulativeIncome: 0,
				remainingBattery: 0,
				power: 0,
				eblockOnLineCount: 0,
				eblockTotalCount: 0,
				eLinkOnlineCount: 0,
				eLinkTotalCount: 0,
				operationTime: 0,
				enableOperationDate: localStorage.getItem("enableOperationDate"),
				capacity: 0,
				headStyle: {
					"color": "#E2E2E2",
					"fontSize": "16px",
					"fontWeight": "600"
				},
				bodyStyle: {
					"color": "#0785fd"
				},
				flag: false,
			}
		},
		computed: {
			stationId() {
				return this.$route.query.stationId
			}
		},
		beforeMount() {
			try {
				this.diagramData = JSON.parse(localStorage.getItem('diagramData'));
				this.meterData = JSON.parse(localStorage.getItem('meterData'));
			} catch (error) {
				console.error(error);				
			}
		},
		mounted() {
			var this_ = this;
			this_.getAlarmCount();
			this_.getData();
			this_.init();
			this_.getDiagramData();
			this_.timer_station_home_data = window.setInterval(function() {
				this_.getAlarmCount();
				this_.getDiagramData();
				this_.getData();
			}, 5000);
			if(this.timer_system_diagram != null) {
				clearInterval(this.timer_system_diagram);
			}
			window.onresize = () => {
				this_.initSystemDiagram(true);
			}
			this_.initSystemDiagram(true);
		},
		methods: {
			getAlarmCount() {
				var this_ = this;
				axios({
					method: 'get',
					url: '/emind/alarm/real/get/count/1/' + localStorage.getItem('stationId') + '/-1',
				}).then(function(d) {
					if(!d) {
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					if(d.data.code === '0') {
						this_.normalAlarm = d.data.data.grade3;
						this_.importantAlarm = d.data.data.grade2;
						this_.urgentAlarm = d.data.data.grade1;

					} else {
						this_.$message.error('请求出错', 2, function() {});
					}
				}).catch(function(error) {
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			getData() {
				var this_ = this;
				var data = {
					"stationId": localStorage.getItem('stationId'),
					"equipmentId": -1,
					"equipmentType": 1,
					"name": 'web',
					"startTime": new Date(0).Format("yyyy-MM-dd hh:mm:ss"),
					"endTime": new Date().Format("yyyy-MM-dd hh:mm:ss")
				}
				axios({
					method: 'post',
					url: '/emind/history/data/home',
					data: data
				}).then(function(d) { //
					if(!d) {
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					this_.timeData = [];
					this_.incomes = [];
					if(d.data.data.dischargeAmount) {
						this_.dischargeAmount = d.data.data.dischargeAmount.toFixed(2);
					} else {
						this_.dischargeAmount = 0;
					}
					if(d.data.data.dalyChargeAmount) {
						this_.chargeAmount = d.data.data.dalyChargeAmount.toFixed(2);
					} else {
						this_.chargeAmount = 0;
					}
					if(d.data.data.todayInCome) {
						this_.income = d.data.data.todayInCome;
					} else {
						this_.income = 0;
					}
					if(data.stationId == 11) {
						this_.income = this_.dischargeAmount * 0.4;
					}
					this_.income = this_.income < 0 ? 0 : this_.income.toFixed(2);
					if(d.data.data.cumulativeDischargeAmount) {
            
						this_.accumulativeDischargeAmount = d.data.data.cumulativeDischargeAmount + parseFloat(this_.dischargeAmount);
						this_.systemEfficiency = this_.accumulativeDischargeAmount;
						this_.accumulativeDischargeAmount = this_.accumulativeDischargeAmount.toFixed(2);
					} else {
						this_.accumulativeDischargeAmount = parseFloat(this_.dischargeAmount);
						this_.systemEfficiency = this_.accumulativeDischargeAmount;
						this_.accumulativeDischargeAmount = this_.accumulativeDischargeAmount.toFixed(2);
					}
					if(d.data.data.cumulativeChargeAmount) {
						this_.accumulativeChargeAmount = d.data.data.cumulativeChargeAmount + parseFloat(this_.chargeAmount);
						if(this_.accumulativeChargeAmount > 1) {
							this_.systemEfficiency = this_.systemEfficiency * 100 / this_.accumulativeChargeAmount;
							this_.systemEfficiency = this_.systemEfficiency.toFixed(2);
							if(this_.systemEfficiency > 92) {
								this_.systemEfficiency = 91.5
								this_.accumulativeDischargeAmount = this_.accumulativeChargeAmount * 0.915;
								this_.accumulativeDischargeAmount = this_.accumulativeDischargeAmount.toFixed(2);
							}
						} else {
							this_.systemEfficiency = 0;
						}
						this_.accumulativeChargeAmount = this_.accumulativeChargeAmount.toFixed(2);
					} else {
						this_.accumulativeChargeAmount = parseFloat(this_.chargeAmount);
						if(this_.accumulativeChargeAmount > 1) {
							this_.systemEfficiency = this_.systemEfficiency * 100 / this_.accumulativeChargeAmount;
							if(this_.systemEfficiency > 92) {
								this_.systemEfficiency = 91.5
								this_.accumulativeDischargeAmount = this_.accumulativeChargeAmount * 0.915;
								this_.accumulativeDischargeAmount = this_.accumulativeDischargeAmount.toFixed(2);
							}
							this_.systemEfficiency = this_.systemEfficiency.toFixed(2);
						} else {
							this_.systemEfficiency = 0;
						}
						this_.accumulativeChargeAmount = this_.accumulativeChargeAmount.toFixed(2);
					}
					if(d.data.data.cumulativeIncome) {
						this_.accumulativeIncome = d.data.data.cumulativeIncome + parseFloat(this_.income);
						this_.accumulativeIncome = this_.accumulativeIncome.toFixed(2);
					} else {
						this_.accumulativeIncome = parseFloat(this_.income).toFixed(2);
					}
					if(data.stationId == 11) {
						this_.accumulativeIncome = this_.accumulativeDischargeAmount * 0.4;
						this_.accumulativeIncome = this_.accumulativeIncome.toFixed(2);
					}
					if(d.data.data.power_DC) {
						this_.power = (d.data.data.power_DC);
					} else {
						this_.power = 0;
					}
					if(d.data.data.designCapacity) {
						this_.capacity = d.data.data.designCapacity;
					}
					if(d.data.data.remainingBattery) {
						this_.remainingBattery = d.data.data.remainingBattery;
						//						this_.remainingBattery = (this_.remainingBattery / this_.capacity * 100).toFixed(1);
					} else {
						this_.remainingBattery = 0;
					}
					if(d.data.data.eNodeOnlineCount) {
						this_.eblockOnLineCount = d.data.data.eNodeOnlineCount;
					} else {
						this_.eblockOnLineCount = 0;
					}
					if(d.data.data.eLinkOnlineCount) {
						this_.eLinkOnlineCount = d.data.data.eLinkOnlineCount;
					} else {
						this_.eLinkOnlineCount = 0;
					}
					if(d.data.data.eNodeTotalCount) {
						this_.eblockTotalCount = d.data.data.eNodeTotalCount;
					} else {
						this_.eblockTotalCount = 0;
					}
					if(d.data.data.eLinkTotalCount) {
						this_.eLinkTotalCount = d.data.data.eLinkTotalCount;
					} else {
						this_.eLinkTotalCount = 0;
					}
					if(d.data.data.operationHours) {
						this_.operationTime = d.data.data.operationHours;
					} else {
						this_.operationTime = 0;
					}
					if(this_.remainingBattery < 0) {
						return;
					}
					var cnt = document.getElementById("count");
					if(!cnt) {
						return;
					}
					var water = document.getElementById("water");
					if(!this_.flag) {
						var percent = parseInt(cnt.innerText);
						var stepSize = this_.remainingBattery > percent ? 1 : -1;
						var percent = percent * 100 / this_.capacity;
						var interval;
						var remainingBatteryShow = 0
						interval = setInterval(function() {
							percent = percent + stepSize;
							remainingBatteryShow = percent * this_.capacity / 100;
							cnt.innerHTML = remainingBatteryShow > 0 ? remainingBatteryShow.toFixed(1) : 0;
							water.style.transform = 'translate(0' + ',' + (100 - percent) + '%)';
							if(Math.abs(percent - (this_.remainingBattery / this_.capacity * 100)) < 1 || remainingBatteryShow < 0) {
								cnt.innerHTML = this_.remainingBattery;
								water.style.transform = 'translate(0' + ',' + (100 - (this_.remainingBattery * 100 / this_.capacity)) + '%)';
								clearInterval(interval);
							}
						}, 60);
						this_.flag = true;
					} else {
						var percent = this_.remainingBattery * 100 / this_.capacity;
						cnt.innerHTML = this_.remainingBattery.toFixed(1);
						water.style.transform = 'translate(0' + ',' + (100 - percent) + '%)';
					}

				}).catch(function(error) {
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			reflush() {
				var this_ = this;
				var newOp = this_.alarmChart.getOption();
				newOp.series[0].data = [{
					itemStyle: {
						color: '#7D2CF5'
					},
					value: this_.normalAlarm,
					name: '三级告警'
				}, {
					itemStyle: {
						color: '#F26813'
					},
					value: this_.importantAlarm,
					name: '二级告警'
				}, {
					itemStyle: {
						color: '#A61D24'
					},
					value: this_.urgentAlarm,
					name: '一级告警'
				}];
				this_.alarmChart.setOption(newOp);

			},
			init() {
				var this_ = this;
				this_.alarmOption = {
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c}%'
					},
					legend: {
						textStyle: {
							color: '#B7B8BC',
						},
						orient: 'horizontal',
						left: 10,
						top: 0,
						data: ['一级告警', '二级告警', '三级告警']
					},
					series: [{
						top: -80,
						name: '实时告警数量',
						type: 'pie',
						radius: ['40%', '50%'],
						center: ['50%', '60%'],
						data: [{
								itemStyle: {
									color: '#7D2CF5'
								},
								value: this_.normalAlarm,
								name: '三级告警'
							},
							{
								itemStyle: {
									color: '#F26813'
								},
								value: this_.importantAlarm,
								name: '二级告警'
							},
							{
								itemStyle: {
									color: '#A61D24'
								},
								value: this_.urgentAlarm,
								name: '一级告警'
							}
						],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				};
				//				this_.alarmChart = echarts.init(document.getElementById("alarm-chart"));
				//				this_.alarmChart.setOption(this_.alarmOption);
				//				setTimeout(() => {
				//					window.onresize = function() {
				//						this_.alarmChart.resize();
				//					}
				//				}, 500);
			},
			getDiagramData() {
				var this_ = this;
				var data = [];
				for(var i = 0; i < this_.meterData.length; i++) {
					data.push(this_.meterData[i].id);
				}
				axios({
					method: 'post',
					url: '/emind/config/diagram/data',
					data: data
				}).then(function(d) { //
					if(!d) {
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					if(d.data.code === '0') {
						for(var i = 0; i < d.data.data.length; i++) {
							for(var j = 0; j < this_.meterData.length; j++) {
								if(d.data.data[i].id == this_.meterData[j].id) {
									if(!this_.meterData[j].id.startsWith("03") && this_.meterData[j].id.indexOf("B") == -1) {
										this_.meterData[j].P = d.data.data[i].power * 1;
										this_.meterData[j].V = d.data.data[i].voltageA;
										this_.meterData[j].I = d.data.data[i].currentA;
										//										this_.meterData[j].P = (2 * this_.meterData[j].P).toFixed(1);
										//										this_.meterData[j].I = (1 * this_.meterData[j].I).toFixed(1);
										//										this_.meterData[j].V = (1.732 * this_.meterData[j].V).toFixed(1);
									} else {
										this_.meterData[j].P = d.data.data[i].power / 10;
										this_.meterData[j].V = d.data.data[i].voltageA / 10;
										this_.meterData[j].I = d.data.data[i].currentA / 10;
									}
									break;
								}
							}
						}
						this_.reflushData(this_.meterData, false);
					} else {
						this_.$message.error('请求出错', 2, function() {});
					}
				}).catch(function(error) {
					console.log(error)
					if(error === 401) {
						return;
					}
					if(error.status === 500 || error.status === 404) {
						this_.$message.error('数据处理错误，请联系管理员', 2, function() {});
						return;
					}
					this_.$message.error('程序出错，请联系管理员', 2, function() {});
				});
			},
			reflushData(data, flag) {
				var this_ = this;
				var dom = null;
				var pathLength = -440;
				var index = null;
				var site_1 = pathLength;
				var site_2 = 0;
				var chargeImg = require("@a/images/system-diagram/bus_bar_charge.png");
				var dischargeImg = require("@a/images/system-diagram/bus_bar_discharge.png");
				var noneImg = require("@a/images/system-diagram/bus_bar_null.png");

				this.stand_still_list.length = 0
				this.charge_list.length = 0
				this.discharge_list.length = 0

				for(var i = 0; i < data.length; i++) {
					const item = data[i]
					const { id } = item
					dom = $('#meter-' + id);
					// var width = dom.css("width").replace("px", "");
					var width = dom.width()
					if(id.startsWith("05")) {
						index = this.discharge_list.indexOf(id);
						if(index > -1) {
							this.discharge_list.splice(index, 1);
						} else {
							index = this.stand_still_list.indexOf(id);
							this.stand_still_list.splice(index, 1);
						}
						this.charge_list.push(id);
						dom.css(
							"background", 'url(' + chargeImg + ')  no-repeat'
						);
					} else {
						if(id.startsWith('06') || id.startsWith('04')) {
							item.P *= -1;
						}
						var p = data[i].P
						if(p > 0) {
							index = this.charge_list.indexOf(data[i].id);
							if(index > -1) {
								this.charge_list.splice(index, 1);
							} else {
								index = this.stand_still_list.indexOf(data[i].id);
								this.stand_still_list.splice(index, 1);
							}
							this.discharge_list.push(data[i].id);
							dom.css(
								"background", 'url(' + dischargeImg + ')  no-repeat'
							);
						} else if(p < 0) {
							index = this.discharge_list.indexOf(data[i].id);
							if(index > -1) {
								this.discharge_list.splice(index, 1);
							} else {
								index = this.stand_still_list.indexOf(data[i].id);
								this.stand_still_list.splice(index, 1);
							}
							this.charge_list.push(data[i].id);
							dom.css(
								"background", 'url(' + chargeImg + ')  no-repeat'
							);
						} else if(p == 0) {
							index = this.charge_list.indexOf(data[i].id);
							if(index > -1) {
								this.charge_list.splice(index, 1);
							}
							index = this.discharge_list.indexOf(data[i].id);
							if(index > -1) {
								this.discharge_list.splice(index, 1);
							}
							this.stand_still_list.push(data[i].id);
							dom.css(
								"background", 'url(' + noneImg + ')  no-repeat'
							);
						}
					}
					dom = $('#meter-P' + data[i].id);
					
					if(typeof(dom.css("width")) == "undefined") {
						continue;
					}
					dom.html(data[i].P + 'kW')
					dom = $('#meter-V' + data[i].id);
					if(typeof(dom.css("width")) == "undefined") {
						continue;
					}
					dom.html(data[i].V + 'V')
					dom = $('#meter-I' + data[i].id);
					if(typeof(dom.css("width")) == "undefined") {
						continue;
					}
					dom.html(data[i].I + 'A')
					dom = $('#meter-' + data[i].id);
					// var width = dom.css("width").replace("px", "");
					var width = dom.width()

					dom.css(
						"background-position", width / 2 - 4 + "px " + site_1 + "px"
					);
				}
				if(flag) {
					if(this.timer_system_diagram != null) {
						clearInterval(this.timer_system_diagram);
					}
					this.timer_system_diagram = window.setInterval(function() {
						this_.animation(site_1, site_2, chargeImg, dischargeImg);
						site_1 += 2;
						if(site_1 > 0) {
							site_1 = pathLength - 3;
						}
						site_2 += -2;
						if(site_2 < -440) {
							site_2 = 0 + 3;
						}
					}, 100);
				}
			},
			animation(site_1, site_2, chargeImg, dischargeImg) {
				var width = 0;
				for(var i = 0; i < this.charge_list.length; i++) {
					// width = $('#meter-' + this.charge_list[i]).css("width").replace("px", "");
					width = $('#meter-' + this.charge_list[i]).width();
					$('#meter-' + this.charge_list[i]).css(
						"background-position", width / 2 - 4 + "px " + site_1 + "px"
					);
				}
				width = 0;
				for(var i = 0; i < this.discharge_list.length; i++) {
					width = $('#meter-' + this.discharge_list[i]).width();
					$('#meter-' + this.discharge_list[i]).css(
						"background-position", width / 2 - 4 + "px " + site_2 + "px"
					);
				}
				for(var i = 0; i < this.stand_still_list.length; i++) {
					width = $('#meter-' + this.stand_still_list[i]).width();
					$('#meter-' + this.stand_still_list[i]).css(
						"background-position", width / 2 - 4 + "px " + site_2 + "px"
					);
				}
			},
			getAllNode(parentDom) {
				if(!parentDom) {
					return
				}
				const getChild = (doms) => {
					for(var i = 0; i < doms.length; i++) {
						if(doms[i].className === 'childrens') {
							var models = 0;
							var lines = 0
							for(var j = 0; j < doms[i].childNodes.length; j++) {
								if(doms[i].childNodes[j].className === 'model-bus-bar') {
									lines += 1;
								} else if(doms[i].childNodes[j].className === 'model') {
									models += 1;
								}
							}
							if(models == 0 || lines == 0) {
								for(var j = 0; j < doms[i].childNodes.length; j++) {
									if(doms[i].childNodes[j].className === 'model-bus-bar') {
										getChild(doms[i].childNodes[j].childNodes);
										doms[i].childNodes[j].style.width = ((100 - lines) / (lines + models) - 1) + '%';
										doms[i].childNodes[j].style.marginLeft = '1%'
									} else if(doms[i].childNodes[j].className === 'model') {
										doms[i].childNodes[j].style.width = ((100 - lines) / (lines + models) - 1) + '%';
										doms[i].childNodes[j].style.marginLeft = '1%'
									}
								}
							} else {
								for(var j = 0; j < doms[i].childNodes.length; j++) {
									if(doms[i].childNodes[j].className === 'model-bus-bar') {
										if(j == 0) {
											doms[i].childNodes[j].style.width = (100 - lines + 1 - (15 * models)) / lines + '%';
										} else {
											doms[i].childNodes[j].style.width = (100 - lines - (15 * models)) / lines + '%';
											doms[i].childNodes[j].style.marginLeft = '1%'
										}
										getChild(doms[i].childNodes[j].childNodes);
									} else if(doms[i].childNodes[j].className === 'model') {
										doms[i].childNodes[j].style.width = '15%';
									}
								}
							}
						}
					}
				};
				getChild(parentDom.childNodes);
			},
			initSystemDiagram(flag) {
				var this_ = this;
				var pathLength = -440;
				var parentDom = document.getElementById("parent");
				this_.getAllNode(parentDom);
				var site_1 = pathLength;
				var site_2 = 0;
				if(this.timer_system_diagram != null) {
					clearInterval(this.timer_system_diagram);
				}
				var width = 0;
				var dom = null;
				var img = null;
				for(var i = 0; i < this_.meterData.length; i++) {
					dom = $('#meter-' + this_.meterData[i].id);
					if(typeof(dom.css("width")) == "undefined") {
						continue;
					}
					// width = dom.css("width").replace("px", "");
					width = dom.width()
					if(this_.meterData[i].P < 0) {
						img = require("@a/images/system-diagram/bus_bar_charge.png");
						dom.css(
							"background", 'url(' + img + ')  no-repeat'
						);
						this_.charge_list.push(this_.meterData[i].id);

					} else if(this_.meterData[i].P > 0) {
						this_.discharge_list.push(this_.meterData[i].id);
						img = require("@a/images/system-diagram/bus_bar_discharge.png");
						dom.css(
							"background", 'url(' + img + ')  no-repeat'
						);
					} else {
						this_.stand_still_list.push(this_.meterData[i].id);
						img = require("@a/images/system-diagram/bus_bar_null.png");
						dom.css(
							"background", 'url(' + img + ')  no-repeat'
						);
					}
					dom.css(
						"background-position", width / 2 - 4 + "px " + site_1 + "px"
					);
				}
				this_.reflushData(this_.meterData, flag);
			}
		},
		destroyed() {
			window.clearInterval(this.timer_station_home_data);
			window.clearInterval(this.timer_system_diagram);
		}
	}
</script>

<style>
	@import '../assets/css/global.css';
	@import '../assets/css/antd.css';
	.body {
		width: 100%;
		height: 100%;
	}
	
	.name {
		height: 18px;
		opacity: 0.8;
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #CDF9FF;
		text-align: center;
		line-height: 30px;
		font-weight: 400;
	}
	
	.data {
		margin-bottom: 20px;
		height: 27px;
		font-family: PingFangSC-Semibold;
		font-size: 18px;
		color: #E2E2E2;
		text-align: center;
		line-height: 45px;
		font-weight: 600;
	}
	/*系统图*/
	
	.transformer {
		margin: auto;
		margin-top: 10px;
		background: url(../assets/images/system-diagram/ic_symbol_one.png) no-repeat;
		width: 30px;
		height: 40px;
		background-size: cover;
		background-size: 100% 100%;
	}
	

	
	.break {
		width: 15px;
		height: 20px;
		background: #FF0000;
		margin: auto;
	}
	
	.meter {
		width: 48px;
		margin: auto;
		overflow: visible;
		margin-top: 5px;
		height: 50px;
		background: url(../assets/images/system-diagram/img_voltage.png) center no-repeat;
		font-size: 100%;
		background-size: contain;
	}
	
	.meterData {
		text-indent: 50px;
		font-size: 12px;
	}
	
	.model {
		float: left;
		height: 100%;
	}
	
	.model-bus-bar {
		float: left;
	}
	
	.bus-barname {
		width: 200px;
		margin-top: -30px;
		float: right;
		text-align: right;
		line-height: 30px;
	}
	
	.body {
		width: 100%;
		height: 100%;
		background: #0c1a22
	}
	
	.big-branch-line-35 {
		left: 100px;
		width: 98%;
		margin-left: 2%;
		height: 5px;
		background: url(../assets/images/system-diagram/branch_line.png);
	}
	
	.branch_line_out-35-10 {
		padding-top: 10px;
		margin: auto;
		overflow: hidden;
		width: 60%;
		min-height: 150px;
		background-position: 0px 0px;
		background: url(../assets/images/system-diagram/bus_bar_charge.png) no-repeat;
	}
	
	.branch_line_out-10-load-3 {
		overflow: hidden;
		margin: auto;
		width: 99%;
		min-width: 52px;
		height: 115px;
		background-position: 1000px 0px;
		background: url(../assets/images/system-diagram/bus_bar_charge.png) no-repeat;
	}
	
	.branch_line_out-10-load-2 {
		/* overflow: hidden; */
		margin: auto;
		width: 99%;
		min-width: 52px;
		height: 114px;
		background-position: 1000px 0px;
		background: url(../assets/images/system-diagram/bus_bar_charge.png) no-repeat;
		padding-top: 10px;
	}
	
	.branch_line_out-10-load-1 {
		overflow: hidden;
		margin: auto;
		width: 99%;
		min-width: 52px;
		height: 290px;
		background-position: 1000px 0px;
		background: url(../assets/images/system-diagram/bus_bar_charge.png) no-repeat;
	}
	
	.branch_line_out-10-load-0 {
		overflow: hidden;
		margin: auto;
		width: 99%;
		min-width: 52px;
		height: 615px;
		background-position: 1000px 0px;
		background: url(../assets/images/system-diagram/bus_bar_charge.png) no-repeat;
	}
	
	.mode-name {
		margin-bottom: 10px;
		width: 100%;
		text-align: center;
	}

	.mode-name img {
		width: 48px;
	}
	
	.box {
		height: 180px;
		width: 180px;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		background: #020438;
		border-radius: 100%;
		overflow: hidden;
	}
	
	.box .percent {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 3;
		width: 100%;
		height: 100%;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: center;
		font-family: PingFangSC-Semibold;
		font-size: 18px;
		color: #E2E2E2;
		line-height: 27px;
		font-weight: 600;
	}
	
	.box .water {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		-webkit-transform: translate(0, 100%);
		transform: translate(0, 100%);
		background: #4D6DE3;
		transition: all .3s;
	}
	
	.box .water_wave {
		width: 200%;
		position: absolute;
		bottom: 100%;
	}
	
	.box .water_wave_back {
		right: 0;
		fill: #C7EEFF;
		-webkit-animation: wave-back 1.4s infinite linear;
		animation: wave-back 1.4s infinite linear;
	}
	
	.box .water_wave_front {
		left: 0;
		fill: #4D6DE3;
		margin-bottom: -1px;
		-webkit-animation: wave-front .7s infinite linear;
		animation: wave-front .7s infinite linear;
	}


	
	@-webkit-keyframes wave-front {
		100% {
			-webkit-transform: translate(-50%, 0);
			transform: translate(-50%, 0);
		}
	}
	
	@keyframes wave-front {
		100% {
			-webkit-transform: translate(-50%, 0);
			transform: translate(-50%, 0);
		}
	}
	
	@-webkit-keyframes wave-back {
		100% {
			-webkit-transform: translate(50%, 0);
			transform: translate(50%, 0);
		}
	}
	
	@keyframes wave-back {
		100% {
			-webkit-transform: translate(50%, 0);
			transform: translate(50%, 0);
		}
	}
</style>


<style>
.ant-card-head-title { 
	text-align: center;
}
</style>
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import axios from 'axios';
import form from 'jquery-form';
export default {
  data: function data() {
    return {
      wrapperCol: {
        span: 10
      },
      labelCol: {
        span: 6
      },
      dateFormat: 'YYYY-MM-DD',
      datePickerLocale: datePickerLocale,
      center: {
        lng: 109.069,
        lat: 34.3757
      },
      zoom: 6,
      stationForm: {
        id: null,
        name: null,
        address: null,
        city: null,
        customer: null,
        longitude: null,
        latitude: null,
        commissioningTime: null,
        afterSalesCall: null,
        powerSupply: null,
        classification: '1',
        operatingModel: '1',
        description: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请填写电站名称！',
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '请在右边地图中选择具体地址！',
          trigger: 'blur'
        }],
        templateId: [{
          required: true,
          message: '请选择模板',
          trigger: 'blur'
        }],
        maxPower: [{
          required: true,
          message: '请填写最大运行功率！',
          trigger: 'blur'
        }],
        customer: [{
          required: true,
          message: '请填写客户名称（最多10个汉字）',
          trigger: 'blur'
        }],
        capacity: [{
          required: true,
          message: '请填写设计容量！',
          trigger: 'blur'
        }],
        powerSupply: [{
          required: true,
          message: '请填写供电单位！',
          trigger: 'blur'
        }],
        classification: [{
          required: true,
          message: '请选择用电类别！',
          trigger: 'blur'
        }],
        operatingModel: [{
          required: true,
          message: '请选择运营模式！',
          trigger: 'blur'
        }],
        afterSalesCall: [{
          required: true,
          message: '请填写售后电话！',
          trigger: 'blur'
        }],
        commissioningTime: [{
          required: true,
          message: '请选择建站日期！',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    var h = window.innerHeight;
    $("#init_body").css("height", h + "px");
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: '/'
      });
      return;
    }

    var this_ = this;
    this_.loginUserId = this_.$store.state.userId;
    this_.initMap(this_.stationForm);
  },
  methods: {
    loginout: function loginout() {
      var this_ = this;
      this.$store.dispatch("users/loginOUt", {
        router: this_.$router
      });
    },
    search: function search() {
      if (this.local != null) {
        this.local.search(this.stationForm.address);
      }
    },
    cacheStations: function cacheStations() {
      axios({
        method: 'post',
        url: '/emind/real/data/cache/stations'
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.$message.warning('服务器更新设备缓存列表成功');
        } else {
          this_.$message.warning('服务器更新设备缓存列表失败');
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    submitForm: function submitForm(formName) {
      var this_ = this;
      this_.$refs[formName].validate(function (valid) {
        if (valid) {
          this_.okFn();
        }
      });
    },
    initMap: function initMap(station) {
      var this_ = this;
      this.mp = new BMap.Map("map");

      if (station == null || station.longitude == null || station.latitude == null) {
        var marker = new BMap.Marker(new BMap.Point(this_.center.lng, this_.center.lat));
        this_.mp.addOverlay(marker);
        this_.mp.centerAndZoom(new BMap.Point(this_.center.lng, this_.center.lat), this_.zoom);
      } else {
        var marker = new BMap.Marker(new BMap.Point(this_.stationForm.longitude, this_.stationForm.latitude));
        this_.mp.addOverlay(marker);
        this_.mp.centerAndZoom(new BMap.Point(station.longitude, station.latitude), this_.zoom);
      }

      this.mp.enableScrollWheelZoom(true);
      this.mp.setMapStyleV2({
        styleId: '9b3a6490a7d226175af527443727c56c'
      });
      this.local = new BMap.LocalSearch(this.mp, {
        renderOptions: {
          map: this_.mp,
          autoViewport: true
        }
      });
      this_.local.setSearchCompleteCallback(function (results) {
        // 判断状态是否正确
        if (this_.local.getStatus() == BMAP_STATUS_SUCCESS) {
          var str = "<ul>";

          for (var i = 0; i < results.getCurrentNumPois(); i++) {
            var poi = results.getPoi(i);
          }
        }
      });
      this.mp.addEventListener("click", function (e) {
        this_.stationForm.longitude = e.point.lng; //通过  e.point.lng获取经度

        this_.stationForm.latitude = e.point.lat; //通过  e.point.lat获取纬度

        this_.zoom = e.target.getZoom();
        var geocoder = new BMap.Geocoder(); //创建地址解析器的实例

        geocoder.getLocation(e.point, function (rs) {
          this_.stationForm.city = rs.addressComponents.city;
          this_.stationForm.address = rs.address;
        });
      });
    },
    okFn: function okFn() {
      var this_ = this;
      var user;
      var data = {
        id: this_.stationForm.id,
        name: this_.stationForm.name,
        address: this_.stationForm.address,
        city: this_.stationForm.city,
        customer: this_.stationForm.customer,
        longitude: this_.stationForm.longitude,
        latitude: this_.stationForm.latitude,
        templateId: this_.stationForm.templateId,
        afterSalesCall: this_.stationForm.afterSalesCall,
        powerSupply: this_.stationForm.powerSupply,
        classification: this_.stationForm.classification,
        operatingModel: this_.stationForm.operatingModel,
        description: this_.stationForm.description
      };
      data.commissioningTime = this_.stationForm.commissioningTime.format("YYYY-MM-DD HH:mm:ss");
      axios({
        method: 'post',
        url: '/emind/config/station/creation',
        data: data
      }).then(function (d) {
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === "0") {
          this_.$message.success('新建电站成功！');
          this_.cacheStations();
          this_.$router.push({
            path: '/distribution'
          });
        } else {
          this_.$message.warning('新建电站失败！');
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    }
  }
};
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
export default {
  data: function data() {
    return {
      picture: require('@a/images/icon/ic_avatar.png'),
      userName: localStorage.getItem("currentUser_name"),
      offset: [-5, 10],
      dot: true,
      breadcrumbs: [],
      openKeys: [''],
      routes: this.$route,
      defaultSelectedKeys: [this.$route.path],
      selectedKeys: [this.$route.path],
      nav_: [],
      alarmCount: 0,
      inlineIndent: 15,
      logo: require('@a/images/logo.png'),
      applogo: require('@a/images/app-logo.png'),
      collapsed: null,
      slideWidth: 1920,
      menuWidth: 200,
      menuOverflow: 'auto',
      slideHeight: 900,
      timer_menu: '',
      background: '#001529'
    };
  },
  watch: {
    "$store.state.localBreadcrumbs": {
      handler: function handler(newVal, oldVal) {
        this.breadcrumbs = newVal;
        localStorage.setItem("localBreadcrumbs", JSON.stringify(this.breadcrumbs));
      }
    }
  },
  mounted: function mounted() {
    if (this.$route.path === '/meter' || this.$route.path === '/eblock' || this.$route.path === '/device-settings') {
      this.defaultSelectedKeys = ['/energy_chain'];
      this.selectedKeys = ['/energy_chain'];
    } else if (this.$route.path === '/income_table') {
      this.defaultSelectedKeys = ['/income_statistics'];
      this.selectedKeys = ['/income_statistics'];
      this.openKeys = ['/data_analysis'];
    } else if (this.$route.path === '/electricity_price') {
      this.defaultSelectedKeys = ['/station_configuration'];
      this.selectedKeys = ['/station_configuration'];
      this.openKeys = ['/configuration_manager'];
    }

    var this_ = this;
    this.slideHeight = window.innerHeight;
    this.slideWidth = window.innerWidth;
    window.addEventListener('resize', function () {
      this_.slideHeight = window.innerHeight;
      this_.slideWidth = window.innerWidth;
    });

    if (this.slideWidth < 1500) {
      this.collapsed = true;
    }

    var this_ = this;
    this_.getAlarmCount();
    this_.timer_menu = window.setInterval(function () {
      this_.getAlarmCount();
    }, 5000);
    this_.getMenuFn();
    this_.defaultOpeneds = [localStorage.getItem('openNum')];
  },
  destroyed: function destroyed() {
    clearInterval(this.timer_menu);
  },
  methods: {
    goToPage: function goToPage(key) {
      if (key == null || key.length < 1) {
        return;
      }

      this.selectedKeys = [key];
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        name: this.stationName,
        url: '/station'
      });

      for (var i = 0; i < this.nav_.length; i++) {
        if (this.breadcrumbs.length > 1) {
          break;
        }

        if (this.nav_[i].url === '/station' || this.nav_[i].url === '/distribution') {
          continue;
        }

        if (this.nav_[i].url === key) {
          this.breadcrumbs.push({
            name: this.nav_[i].name,
            url: this.nav_[i].url
          });
          break;
        }

        if (this.nav_[i].children.length != 0) {
          for (var j = 0; j < this.nav_[i].children.length; j++) {
            if (this.nav_[i].children[j].url === key) {
              //							this.breadcrumbs.push({
              //								name: this.nav_[i].name,
              //								url: this.nav_[i].url,
              //							});
              localStorage.setItem("openKey", this.nav_[i].url);

              if (!this.collapsed) {
                this.openKeys = [this.nav_[i].url];
              }

              this.breadcrumbs.push({
                name: this.nav_[i].children[j].name,
                url: this.nav_[i].children[j].url
              });
            }
          }
        } else {
          localStorage.setItem("openKey", '');
          this.openKeys = [''];
        }
      }

      localStorage.setItem("localBreadcrumbs", JSON.stringify(this.breadcrumbs));
      this.$store.commit("setLocalBreadcrumbs", this.breadcrumbs, {
        root: true
      });
      this.$router.push(key);
    },
    onOpenChange: function onOpenChange(openKeys) {
      if (openKeys.length !== 0) {
        this.openKeys = [openKeys[1]];
      } else {
        this.openKeys = [''];
      }
    },
    clickItem: function clickItem(_ref) {
      var key = _ref.key;
      this.selectedKeys = [key];
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        name: this.stationName,
        url: '/station'
      });

      for (var i = 0; i < this.nav_.length; i++) {
        if (this.breadcrumbs.length > 1) {
          break;
        }

        if (this.nav_[i].url === '/station' || this.nav_[i].url === '/distribution') {
          continue;
        }

        if (this.nav_[i].url === key) {
          this.breadcrumbs.push({
            name: this.nav_[i].name,
            url: this.nav_[i].url
          });
          break;
        }

        if (this.nav_[i].children.length != 0) {
          for (var j = 0; j < this.nav_[i].children.length; j++) {
            if (this.nav_[i].children[j].url === key) {
              //							this.breadcrumbs.push({
              //								name: this.nav_[i].name,
              //								url: this.nav_[i].url,
              //							});
              localStorage.setItem("openKey", this.nav_[i].url);
              this.openKeys = [this.nav_[i].url];
              this.breadcrumbs.push({
                name: this.nav_[i].children[j].name,
                url: this.nav_[i].children[j].url
              });
            }
          }
        } else {
          localStorage.setItem("openKey", '');
          this.openKeys = [''];
        }
      }

      localStorage.setItem("localBreadcrumbs", JSON.stringify(this.breadcrumbs));
      this.$store.commit("setLocalBreadcrumbs", this.breadcrumbs, {
        root: true
      });
      this.$router.push(key);
    },
    getMenuFn: function getMenuFn() {
      //获取侧边栏信息
      var this_ = this;
      axios({
        method: 'get',
        url: 'emind/user/menus/page'
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.status === "2000") {
          this_.nav_ = d.data.data;
          this_.nav_.sort(this_.sortId);
          this_.nav_.map(function (item) {
            item.children.sort(this_.sortId);
          });
        } else {
          this_.$message.error(d.data.message, 2, function () {});
        }

        this_.stationName = localStorage.getItem("stationName");
        this_.breadcrumbs.push({
          name: this_.stationName,
          url: '/station'
        });
        var openKey = localStorage.getItem("openKey");

        if (openKey && openKey.length > 0) {
          this_.openKeys = [openKey];
        }

        if (this_.openKeys[0].length == 0) {
          this_.nav_.map(function (item) {
            if (item.url === this_.selectedKeys[0]) {
              this_.breadcrumbs.push({
                name: item.name,
                url: item.url
              });
            }
          });
        } else {
          this_.nav_.map(function (item) {
            if (item.url === this_.openKeys[0]) {
              item.children.map(function (child) {
                if (child.url === this_.selectedKeys[0]) {
                  this_.breadcrumbs.push({
                    name: child.name,
                    url: child.url
                  });
                }
              });
            }
          });
        }

        if (this_.$route.path === '/station' && this_.breadcrumbs.length > 1) {
          this_.breadcrumbs.splice(1, 1);
        }

        if (this_.$route.path === '/meter' || this_.$route.path === '/eblock' || this_.$route.path === '/device-settings' || this_.$route.path === '/income_table' || this_.$route.path === '/electricity_price') {
          var localBreadcrumbs = localStorage.getItem("localBreadcrumbs");

          if (localBreadcrumbs != null && localBreadcrumbs.length > 0) {
            this_.breadcrumbs = JSON.parse(localBreadcrumbs, function (key, value) {
              return key.indexOf('date') >= 0 ? new Date(value) : value;
            });
          }
        }

        if (this_.slideWidth >= 1500) {
          var localCollapsed = localStorage.getItem("collapsed");

          if (localCollapsed == null) {
            this_.collapsed = false;
          } else {
            if (localCollapsed === 'true') {
              this_.collapsed = true;
              this_.openKeys = [''];
            } else {
              this_.collapsed = false;
            }
          }
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    getAlarmCount: function getAlarmCount() {
      var this_ = this;
      axios({
        method: 'get',
        url: '/emind/alarm/real/get/count/1/' + localStorage.getItem('stationId') + '/-1'
      }).then(function (d) {
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.alarmCount = d.data.data.grade1 + d.data.data.grade2 + d.data.data.grade3;
          this_.hasNewAlarm = d.data.data.hasNewAlarm == 0 ? false : true;
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    toggleCollapsed: function toggleCollapsed() {
      if (this.slideWidth < 1500) {
        return;
      }

      var span = null;

      if (!this.collapsed) {
        this.menuWidth = 90;
        this.module = 40;
      } else {
        var openKey = localStorage.getItem("openKey");

        if (openKey != null && openKey.length > 0) {
          this.openKeys = [openKey];
        }

        this.module = 10;
        this.menuWidth = 200;
      }

      this.collapsed = !this.collapsed;
      localStorage.setItem("collapsed", this.collapsed);
    },
    loginout: function loginout() {
      var self = this;
      this.$confirm({
        title: '确认',
        content: '确定要退出吗？',
        okText: '确认',
        onOk: function onOk() {
          self.$store.dispatch("users/loginOUt", {
            router: self.$router
          });
        },
        cancelText: '取消'
      });
    },
    go_alarm_record: function go_alarm_record() {
      if (this.alarmCount > 0) {
        if (this.$route.path != '/alarm_record') {
          this.goToPage('/alarm_record');
        }
      }
    }
  }
};
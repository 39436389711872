<template>
	<div>
		<a-modal v-model="modelShow" :footer="null" :centered="true" :closable="false" :keyboard="false" :maskClosable="false">
	     	<div style="height: 30px;">
	     		<span><a-icon type="exclamation-circle" style="color: #3BA1FF; fontSize: 20px;"/></span>
	     		<span style=" font-size: 16px; margin-left: 10px;">提示：</span>
	     		<p style=" font-size: 14px; margin-left: 30px; margin-top: 10px;">当前系统中暂无电站，点击“新建电站”按钮跳转至新建电站界面</p>
	     	</div>
	     	<div style="padding-left: 30px; height: 50px;"></div>
	     	<div style="margin-bottom: 10px;  height: 30px;">
				<a-form-model layout="inline" style="width: 100%;">
					<a-form-model-item style="float: right;">
						<a-button type="primary" @click="confirm">新建电站</a-button>
					</a-form-model-item>
					<a-form-model-item style="float: right;">
						<a-button @click="closeModel">退出系统</a-button>
					</a-form-model-item>
				</a-form-model>
			</div>
	    </a-modal>
		<div :style="{height: '100vh'}">
			<div class="btnLayer">
				<div class="search">
					<div class="searchInner">
						<a href="javascript:;" class="searchBtn"><my-icon class="home-icon" type="icon-dashujukeshihuaico-" style="color: rgba(0, 255, 240, 1); cursor: pointer;"></my-icon></a>
						<form class="searchForm">
							<my-icon class="home-icon" @click="search" type="icon-dashujukeshihuaico-" style="color: rgba(0, 255, 240, 1); cursor: pointer;"></my-icon>
							<input class="searchInput" id="stationName" v-model="searchKey" type="text" placeholder="请搜索电站" @input="search" @keydown.enter="search" autocomplete="off" style="font-size: 14px;">
							<input type="text" hidden="hidden" />
						</form>
						<div class="searchSuggest" v-if="suggestList.length > 0">
							<div class="searchSuggestInfo">共有电站 <span style="color: #fff;">{{stations.length}}</span> 个</div>
							<ol class="searchSuggestList">
								<li class="searchSuggestItem" @click="selectStation(station)" v-for="(station,index) in suggestList" :key="station.id" :title="station.name">{{station.name}}</li>
							</ol>
						</div>
					</div>
					
				</div>
			</div>
			<div class="capacity">
				<div class="icon">
					<img src="@a/images/icon/ic_zhuagnjirongliang.png" width="100%" />
				</div>
				<div class="home_data">
					{{capacity}} kWh
				</div>
				<div class="home_name">
					装机容量
				</div>
			</div>
			<div class="power">
				<div class="icon">
					<img src="@a/images/icon/ic_zhuagnjiggonglv.png" width="100%" />
				</div>
				<div class="home_data">
					{{power}} kW
				</div>
				<div class="home_name">
					装机功率
				</div>
			</div>
			<div class="stationCount">
				<div class="icon">
					<img src="@a/images/icon/ic_dianzhanshuliang.png" width="100%" />
				</div>
				<div class="home_data">
					{{stationCount}}
				</div>
				<div class="home_name">
					电站数量
				</div>
			</div>
			<div class="energySaving">
				<div class="capacity">
					<div class="icon">
						<img src="@a/images/icon/ic_co_emmit.png" width="100%" />
					</div>
					<div class="home_data">
						{{(cumulativeDischargeAmount * 0.997 / 1000).toFixed(1)}} t
					</div>
					<div class="home_name">
						<span>CO</span>
						<span style="font-size: 10%;">2</span>
						<span>减排量</span>
					</div>
				</div>
				<div class="power">
					<div class="icon">
						<img src="@a/images/icon/ic_mei.png" width="100%" />
					</div>
					<div class="home_data">
						{{(cumulativeDischargeAmount * 0.4 / 1000).toFixed(1)}} t
					</div>
					<div class="home_name">
						节约标准煤
					</div>
				</div>
				<div class="stationCount">
					<div class="icon">
						<img src="@a/images/icon/ic_tree.png" width="100%" />
					</div>
					<div class="home_data">
						{{(cumulativeDischargeAmount * 0.997 / 1800).toFixed(0)}} 棵
					</div>
					<div class="home_name">
						减少森林砍伐
					</div>
				</div>
			</div>
			<div class="statisticalData">
				<div class="title">
					收益统计
				</div>
				<div class="home_list">
					<div class="home_text">
						<span class="left"><img src="@a/images/icon/ic_income_month.png"/></span>
						<span class="name" style="padding-left: 10px;">今日收益（元）</span>
						<span style="color: #3AFFEF; float: right; font-size: 19px;">{{todayInCome}}</span>
					</div>
					<div class="hr"> </div>
					<div class="home_text">
						<span class="left"><img src="@a/images/icon/ic_income_all.png"/></span>
						<span class="name" style="padding-left: 10px;">累计收益（元）</span>
						<span style="float: right; font-size: 19px;">{{cumulativeIncome}}</span>
					</div>
				</div>
				<div id="incom" class="homeChart">

				</div>
				<div class="title">
					电量统计
				</div>
				<div class="home_list">
					<div class="home_text">
						<span class="left"><img src="@a/images/icon/ic_charge_month.png"/></span>
						<span class="name" style="padding-left: 10px;">今日储放（kWh）</span>
						<span style="color: #14F690; float: right; font-size: 19px;">{{dischargeAmount}}</span>
					</div>
					<div class="hr"> </div>
					<div class="home_text">
						<span class="left"><img src="@a/images/icon/ic_charge_all.png"/></span>
						<span class="name" style="padding-left: 10px;">累计储放（kWh）</span>
						<span style="float: right; font-size: 19px;">{{cumulativeDischargeAmount}}</span>
					</div>
				</div>
				<div id="discharge" class="homeChart">

				</div>
			</div>
			<div class="home_map" id="map" :style="{height: '100vh'}"></div>
		</div>
		<div class="nav-top">
			<img src="@a/images/img_home_header.png" width="100%" />
			<span class="nav-top-title">奇点星云eMind智慧储能电站管理平台</span>
			<div class="nav-top-time">
				<span id="time">{{now}}</span>
			</div>
			<div class="logout">
				<my-icon class="home-icon" type="icon-tuichu" style="color: rgba(0, 255, 240, 1); cursor: pointer;" @click="loginout"></my-icon>
			</div>
		</div>
		<div class="record_number">
			<span>网站备案/许可证号：</span>
        	<span><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">陕ICP备20010223号-1</a></span>
		</div>
	</div>
</template>

<script>
	import diagramConfig from '@/assets/json/data.json'
	import axios from 'axios'
	import $ from 'jquery'
	export default {
		data() {
			return {
				modelShow: false,
				bmap: null,
				searchFlag: false,
				isShowSearchInput: true,
				offset: {
					"width": 2,
					"height": -10
				},
				stationCount: 0,
				power: 0,
				capacity: 0,
				offlineIcon: require('@a/images/icon/ic_map_powerstation_offline.png'),
				standStillIcon: require('@a/images/icon/ic_map_powerstation_normal.png'),
				bootUpIcon: require('@a/images/icon/ic_map_powerstation_alert.png'),
				now: new Date().Format("yyyy年MM月dd日  hh:mm:ss    WW"),
				searchOption: {
					city: '上海',
					citylimit: true
				},
				slideHeight: 900,
				slideWidth: 1920,
				station: {
					id: null,
					longitude: null,
					latitude: null,
					name: null,
					address: null
				},
				timer_1: "",
				center: {
					lng: 108.404,
					lat: 34.515
				},
				zoom: 6,
				electricalEnergyChart: null,
				electricalEnergyOption: null,
				effectivenessChart: null,
				effectivenessOption: null,
				incomeChart: null,
				incomeOption: null,
				todayInCome: 0,
				dischargeAmount: 0,
				cumulativeIncome: 0,
				cumulativeDischargeAmount: 0,
				effectivenessArray: [],
				sharpPeriodChargeElectricEnergy: [],
				peakPeriodChargeElectricEnergy: [],
				flatPeriodChargeElectricEnergy: [],
				valleyPeriodChargeElectricEnergy: [],
				sharpPeriodDischargeElectricEnergy: [],
				peakPeriodDischargeElectricEnergy: [],
				flatPeriodDischargeElectricEnergy: [],
				valleyPeriodDischargeElectricEnergy: [],
				incomes: [],
				timeDate: [],
				stations: [],
				meterPerPixel: 500000 / 115, 
				mp: null,
				labels:[],


				/* ------------- */
				searchKey: '',
				suggestList: []
			}
		},
		mounted() {
			localStorage.setItem("localBreadcrumbs", "");
			var this_ = this;
			this_.getStationInfo();
			var dayCount = this.getCurrentMonthLast().Format("dd");
			var firstCount = this.getCurrentMonthFirst().getTime();
			for(var i = 0; i < dayCount; i++) {
				this.timeDate.push(new Date(firstCount + 86400000 * i).Format("dd"));
			}
			this_.timer_1 = window.setInterval(function() {
				this_.now = new Date().Format("yyyy年MM月dd日  hh:mm:ss   WW");
			}, 1000);
			// this.slideHeight = window.innerHeight;
			// this.slideWidth = window.innerWidth;
			$(".nav-top-time").css("right", window.innerWidth / 1920 * 240 + "px");
			$(".nav-top-time").css("padding", window.innerWidth / 1920 * 8 + "px");
			window.addEventListener('resize', function() {
				$(".nav-top-time").css("right", window.innerWidth / 1920 * 240 + "px");
				$(".nav-top-time").css("padding", window.innerWidth / 1920 * 8 + "px");
				// this_.slideHeight = window.innerHeight;
				// this_.slideWidth = window.innerWidth;
			});
			this.initMap();
			this_.init("discharge", "effectiveness", "incom");

			$('.searchBtn').on('click', function() {
				$(this).hide();
				$('.searchInner').addClass('open');
				setTimeout(function() {
					$('.searchInner').find('form').show(function(){
						this_.suggestList = this_.stations.slice()
					});
				}, 400);
			});
			$('body').on('click', function() {
				$('.searchInner').find('form').hide();
				$("#stationName").val("");
				$('.searchInner').removeClass('open');
				setTimeout(function() {
					$('.searchBtn').show();
				}, 400);
				this_.suggestList = []
			});
			$('.search').on('click', function(event) {
				event.stopPropagation();
			});
		},
		methods: {
			confirm(){
				this.$router.push("/init");
			},
			closeModel(){
				this.loginout();
			},
			initMap(){
				let mapConfig = sessionStorage.getItem('emind.mapConfig')
				
				if (mapConfig != null) {
					mapConfig = JSON.parse(mapConfig)
					Object.assign(this, mapConfig)
				}

				var this_ = this;
				this.mp = new BMap.Map("map");
				var point = new BMap.Point(this_.center.lng, this_.center.lat);
				this.mp.centerAndZoom(point, this_.zoom);
				this.mp.enableScrollWheelZoom();
				this.mp.disableInertialDragging();
				this.mp.setMapStyleV2({     
				  	styleId: '2589c0a5009689223e4ad38d54bb134e'
				});
				var loadCount = 1;
				this.mp.addEventListener("tilesloaded",function(){
				    if(loadCount == 1){
				        this_.mp.setCenter(point);
				    }
				    loadCount = loadCount + 1;
				});
				this.mp.addEventListener( "dragend" , function(evt){
				 	var centerPoint=this_.mp.getCenter();
 					var maxLng = 180 - this_.slideWidth * this_.meterPerPixel/ 2 / (43075700 / 360);
 					if(centerPoint.lng > maxLng){
   						this_.mp.centerAndZoom(new BMap.Point(maxLng, centerPoint.lat), this_.zoom);
 					}
 					var minLng = -180 + this_.slideWidth * this_.meterPerPixel/ 2 / (43075700 / 360);
 					if(centerPoint.lng < minLng){
   						this_.mp.centerAndZoom(new BMap.Point(minLng, centerPoint.lat), this_.zoom);
 					}
				});
				this.mp.addEventListener( "dragging" , function(evt){
				 	var centerPoint=this_.mp.getCenter();
 					var maxLng = 180 - this_.slideWidth * this_.meterPerPixel/ 2 / (43075700 / 360);
 					if(centerPoint.lng > maxLng){
   						this_.mp.centerAndZoom(new BMap.Point(maxLng, centerPoint.lat), this_.zoom);
 					}
 					var minLng = -180 + this_.slideWidth * this_.meterPerPixel/ 2 / (43075700 / 360);
 					if(centerPoint.lng < minLng){
   						this_.mp.centerAndZoom(new BMap.Point(minLng, centerPoint.lat), this_.zoom);
 					}
				});
				this.mp.addEventListener( "dragstart" , function(evt){
				 	var centerPoint=this_.mp.getCenter();
 					var maxLng = 180 - this_.slideWidth * this_.meterPerPixel/ 2 / (43075700 / 360);
 					if(centerPoint.lng > maxLng){
   						this_.mp.centerAndZoom(new BMap.Point(maxLng, centerPoint.lat), this_.zoom);
 					}
 					var minLng = -180 + this_.slideWidth * this_.meterPerPixel/ 2 / (43075700 / 360);
 					if(centerPoint.lng < minLng){
   						this_.mp.centerAndZoom(new BMap.Point(minLng, centerPoint.lat), this_.zoom);
 					}
				});
				this.mp.addEventListener( "resize" , function(evt){
				 	var centerPoint=this_.mp.getCenter();
 					var maxLng = 180 - this_.slideWidth * this_.meterPerPixel/ 2 / (43075700 / 360);
 					if(centerPoint.lng > maxLng){
   						this_.mp.centerAndZoom(new BMap.Point(maxLng, centerPoint.lat), this_.zoom);
 					}
 					var minLng = -180 + this_.slideWidth * this_.meterPerPixel/ 2 / (43075700 / 360);
 					if(centerPoint.lng < minLng){
   						this_.mp.centerAndZoom(new BMap.Point(minLng, centerPoint.lat), this_.zoom);
 					}
				});
				this.mp.addEventListener('zoomend', () => {
					this_.zoom = this_.mp.getZoom();
					console.log('zoom is', this_.zoom);
					switch (this_.zoom){
						case 2:
							this_.meterPerPixel = 5000000 / 70;
							break;
						case 3:
							this_.meterPerPixel = 2000000 / 55;
							break;
						case 4:
							this_.meterPerPixel = 2000000 / 115;
							break;
						case 5:
							this_.meterPerPixel = 1000000 / 115;
							break;
						case 6:
							this_.meterPerPixel = 500000 / 115;
							break;
						case 7:
							this_.meterPerPixel = 200000 / 91;
							break;
						case 8:
							this_.meterPerPixel = 100000 / 176;
							break;
						case 9:
							this_.meterPerPixel = 50000 / 91;
							break;
						case 10:
							this_.meterPerPixel = 20000 / 72;
							break;
						case 11:
							this_.meterPerPixel = 10000 / 72;
							break;
						case 12:
							this_.meterPerPixel = 5000 / 72;
							break;
						case 13:
							this_.meterPerPixel = 2000 / 57;
							break;
						case 14:
							this_.meterPerPixel = 2000 / 118;
							break;
						case 15:
							this_.meterPerPixel = 1000 / 118;
							break;
						case 16:
							this_.meterPerPixel = 500 / 118;
							break;
						case 17:
							this_.meterPerPixel = 200 / 93;
							break;
						case 18:
							this_.meterPerPixel = 100 / 93;
							break;
						case 19:
							this_.meterPerPixel = 50 / 93;
							break;
						case 20:
							this_.meterPerPixel = 20 / 74;
							break;
					}
					var centerPoint=this_.mp.getCenter();
 					var maxLng = 180 - this_.slideWidth * this_.meterPerPixel/ 2 / (42075700 / 360);
 					if(centerPoint.lng > maxLng){
   						this_.mp.centerAndZoom(new BMap.Point(maxLng, centerPoint.lat), this_.zoom);
 					}
 					var minLng = -180 + this_.slideWidth * this_.meterPerPixel/ 2 / (42075700 / 360);
 					if(centerPoint.lng < minLng){
   						this_.mp.centerAndZoom(new BMap.Point(minLng, centerPoint.lat), this_.zoom);
 					}

					this.toggleLabel(this.zoom)
				
				});


			},
			loginout() {
				const self = this
				this.$confirm({
					title: '确认',
					content: '确定要退出吗？',
					okText: '确认',
					onOk() {
						self.$store.dispatch("users/loginOUt", {
							router: self.$router
						});
					},
					cancelText: '取消',
				})
			},
			search(event) {
				var name = $("#stationName").val();

				this.suggestList = this.stations.filter(station => {
					return station.name.includes(name)
				})
				// if(name.length > 0) {
				// 	for(var i = 0; i < this.stations.length; i++) {
				// 		if(this.stations[i].name.indexOf(name) != -1 && this.stations[i].longitude != this.center.lng && this.stations[i].latitude != this.center.lat) {
				// 			this.center = {
				// 				lng: this.stations[i].longitude,
				// 				lat: this.stations[i].latitude
				// 			}
				// 			this.station = this.stations[i];
				// 			this.lookDetail(this.stations[i])
				// 			return;
				// 		}
				// 	}
				// }
			},
			selectStation(station) {
				this.center = {
					lng: station.longitude,
					lat: station.latitude
				}
				this.station = station;
				this.lookDetail(station)
				this.mp.centerAndZoom(new BMap.Point(station.longitude, station.latitude), 12)
			},
			goStation(station) {
				console.log('stationId:', this.station.id);
				
				var config = diagramConfig
				let is_find = false

				for(var i = 0; i < config.length; i++) {
					if(config[i].name === 'diagramData_' + this.station.id) {
						localStorage.setItem('diagramData', JSON.stringify(config[i].diagramData));
						localStorage.setItem('meterData', JSON.stringify(config[i].meterData));
						is_find = true
						break;
					}
				}

				// 未配置时数据设为空 以防止报错
				if (!is_find) {
					localStorage.setItem('diagramData', '[]');
					localStorage.setItem('meterData', '[]');
				}

				localStorage.setItem('strategyId', this.station.strategyId);
				localStorage.setItem('optimalStrategyId', this.station.optimalStrategyId);
				localStorage.setItem('stationId', this.station.id);
				localStorage.setItem('stationName', this.station.name);
				localStorage.setItem('timePriceId', this.station.timePriceId);
				localStorage.setItem('restModeType', this.station.restMode);
				localStorage.setItem('enableOperationDate', new Date(this.station.commissioningTime).Format("yyyy/MM/dd"));
				
				let center = this.mp.getCenter()
				let zoom = this.mp.getZoom()
				let mapConfig = {
					center,
					zoom
				}
				sessionStorage.setItem('emind.mapConfig', JSON.stringify(mapConfig));

				this.$router.push({
					path: "/station",
					query: {
						stationId: this.station.id
					}
				});
			},
			lookDetail(station) {
				var this_ = this;
				var opts = {    
				    width : 300,     // 信息窗口宽度    
				    height: 90,     // 信息窗口高度    
				    title : station.name,  // 信息窗口标题   
				    offset: new BMap.Size(-8, -25)
				} 
				var infoWindow = new BMap.InfoWindow("地址：" + station.address + "<br />装机容量：" + (station.maxPower == null ? 0 : station.maxPower) + 'kW/' +  (station.capacity == null ? 0 : station.capacity) +'kWh<br/><!--<a style="color: #3AFFEF; line-height: 40px; cursor: pointer;" id="goToStation">点击进入</a>-->', opts); 
				this.mp.openInfoWindow(infoWindow, new BMap.Point(station.longitude, station.latitude));      // 打开信息窗口
				this.station = station;
				// setTimeout(function() {
				// 	var dom = document.getElementById("goToStation");
				// 	dom.addEventListener("click", function () {
				// 		this.station = station;
				// 		this_.goStation();
				// 	});
				// }, 100);
			},
			getStationInfo() {
				var this_ = this;
				axios({
					method: 'get',
					url: '/emind/config/station/list'
				}).then(function(d) { //
					if(!d){
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					if(d.data.code === '0') {
						this_.stations = d.data.data;
						if(this_.stations.length < 1){
							this_.modelShow = true;
							return;
						}
						this_.getIncomeData();
						this_.stationCount = 0;
						this_.stations.map(function(station) {
							if(station.longitude!=null && station.longitude!=null){
								this_.stationCount += 1;
								station.flag = this_.bootUpIcon;
								var myIcon = new BMap.Icon(this_.bootUpIcon, new BMap.Size(32, 50), {
						        // 指定定位位置。   
						        // 当标注显示在地图上时，其所指向的地理位置距离图标左上    
						        // 角各偏移10像素和25像素。您可以看到在本例中该位置即是   
						        // 图标中央下端的尖角位置。    
						        anchor: new BMap.Size(25, 30),
						        // 设置图片偏移。   
						        // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您   
						        // 需要指定大图的偏移位置，此做法与css sprites技术类似。    
							    });   
							    var marker = new BMap.Marker(new BMap.Point(station.longitude, station.latitude), {icon: myIcon});
								var label = new BMap.Label(station.name, {
									offset: new BMap.Size(- station.name.length * 5, 50),
								})
								label.setStyle({
									color: '#CAF6FC',
									border: 'none',
									background: 'transparent',
									display: 'none'
								})
								this_.labels.push(label)
								marker.setLabel(label)
							    marker.addEventListener("click", function(){ 
							    	this.station = station;
								    this_.goStation();    
								}); 
								marker.addEventListener("mouseover", function(){    
								    this_.lookDetail(station);    
								});
								
								marker.addEventListener("mouseout", function(){    
								    this_.mp.closeInfoWindow()
								});
								this_.mp.addOverlay(marker);                     // 将标注添加到地图中 
								this_.power += station.maxPower;
								this_.capacity += station.capacity;
							}
						});
						this_.toggleLabel(this_.zoom)

					} else {
						this_.$message.error('请求出错', 2, function() {});
					}
				}).catch(function(error) {
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			getCurrentMonthFirst() {
				var date = new Date();
				date.setDate(1);
				date.setHours(0, 0, 0, 0);
				return date;
			},
			getCurrentMonthLast() {
				var date = new Date();
				var currentMonth = date.getMonth();
				var nextMonth = ++currentMonth;
				var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
				var oneDay = 1000 * 60 * 60 * 24;
				return new Date(nextMonthFirstDay - oneDay);
			},
			getIncomeData() {
				var this_ = this;
				var data = {
					"stationId": -1,
					"equipmentId": -1,
					"equipmentType": 1,
					"name": 'web',
					"endTime": new Date().Format("yyyy-MM-dd hh:mm:ss"),
					"startTime": new Date().Format("yyyy-MM") + "-01 00:00:00",
				}
				axios({
					method: 'post',
					url: '/emind/history/data/home',
					data: data
				}).then(function(d) { //
					if(!d){
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					this_.incomes = [];
					this_.efficiency = 0;
					this_.chargeAmount = 0;
					this_.dischargeAmount = 0;
					this_.effectivenessArray = [];
					this_.dischargeElectricEnergy = [];
					this_.chart_x = [];
					var now = new Date(new Date(new Date().toLocaleDateString())).getTime();
					if(d.data.data.todayInCome) {
						this_.todayInCome = d.data.data.todayInCome.toFixed(2);
					} else {
						this_.todayInCome = 0;
					}
					this_.todayInCome = this_.todayInCome < 0 ? 0 : this_.todayInCome;
					if(d.data.data.dischargeAmount) {
						this_.dischargeAmount = d.data.data.dischargeAmount.toFixed(1);
					} else {
						this_.dischargeAmount = 0;
					}
					if(d.data.data.cumulativeIncome) {
						this_.cumulativeIncome = d.data.data.cumulativeIncome + parseFloat(this_.todayInCome);
						this_.cumulativeIncome = this_.cumulativeIncome.toFixed(2);
					} else {
						this_.cumulativeIncome = parseFloat(this_.todayInCome).toFixed(2);
					}
					if(d.data.data.cumulativeDischargeAmount) {
						this_.cumulativeDischargeAmount = d.data.data.cumulativeDischargeAmount + parseFloat(this_.dischargeAmount);
						this_.cumulativeDischargeAmount = this_.cumulativeDischargeAmount.toFixed(1);
					} else {
						this_.cumulativeDischargeAmount = parseFloat(this_.dischargeAmount);
					}
					this_.maxPower = d.data.data.maxPower;
					if(this_.designCapacity > 10000) {
						this_.designCapacity = d.data.data.designCapacity /= 10000;
						this_.maxPower /= 10000;
						this_.powerMagnitude = 1;
					} else {
						this_.designCapacity = (d.data.data.designCapacity);
					}
					if(isNaN(this_.designCapacity)) {
						this_.designCapacity = 0;
					}
					if(isNaN(this_.maxPower)) {
						this_.designPower = 0;
					} else {
						this_.designPower = this_.maxPower;
					}
					if(isNaN(this_.todayInCome)) {
						this_.todayInCome = 0;
					}
					if(isNaN(this_.dischargeAmount)) {
						this_.dischargeAmount = 0;
					}
					if(isNaN(this_.cumulativeIncome)) {
						this_.cumulativeIncome = 0;
					}
					if(isNaN(this_.power_DC)) {
						this_.power_DC = 0;
					}
					if(isNaN(this_.cumulativeDischargeAmount)) {
						this_.cumulativeDischargeAmount = 0;
					}
					if(d.data.data.list) {
						d.data.data.list.forEach(function(x) {
							this_.incomes.push([new Date(x.date.toString().replace(/-/g, "/")).Format("dd"), x.todayInCome.toFixed(2)]);
							if(x.accumulativeChargeElectricEnergy == 0) {
								this_.effectivenessArray.push([new Date(x.date.toString().replace(/-/g, "/")).Format("dd"), 0]);
							} else {
								this_.effectivenessArray.push([new Date(x.date.toString().replace(/-/g, "/")).Format("dd"), (x.accumulativeDischargeElectricEnergy / x.accumulativeChargeElectricEnergy).toFixed(3)]);
							}
							if(x.dischargeAmount == 0) {
								this_.dischargeElectricEnergy.push([new Date(x.date.toString().replace(/-/g, "/")).Format("dd"), 0]);
							} else {
								this_.dischargeElectricEnergy.push([new Date(x.date.toString().replace(/-/g, "/")).Format("dd"), (x.dischargeAmount)]);
							}
						});
						this_.incomes.reverse();
					}
					if(this_.chargeAmount > 1) {
						this_.efficiency = (this_.dischargeAmount / this_.chargeAmount * 100).toFixed(2);
					}
					this_.reflush(1);
					this_.reflush(2);
				}).catch(function(error) {
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			init(electricalEnergyId, effectivenessId, incomeId) {
				var this_ = this;
				this_.effectivenessOption = {
					animation: false,
					color: ['#f26813'],
					tooltip: {
						trigger: 'axis',
						formatter: function(params) {
							var relVal = '时间：' + new Date().Format("MM月") + params[0].value[0] + '日' + "<br/>";
							relVal = relVal + '效率：' + (params[0].value[1] * 100).toFixed(1) + '%';
							return relVal;
						}
					},
					grid: {
						left: 35,
						right: 10,
						height: 120,
						top: 35,
					},
					xAxis: {
						axisLine: {
							lineStyle: {
								color: '#1A679B'
							}
						},
						boundaryGap: false,
						type: 'category',
						data: this_.timeDate
					},
					yAxis: {
						nameTextStyle: {
							fontFamily: "微软雅黑",
							color: '#CDF9FF',
							fontSize: 10
						},
						axisLine: {
							lineStyle: {
								color: '#1A679B',
								width: 1, //这里是为了突出显示加上的
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#1A679B'],
								width: 1,
								type: 'solid'
							}
						},
						axisLabel: {
							formatter: function(params, index) {
								return params * 100;
							}
						},
						name: '效率(%)',
						type: 'value'
					},
					series: [{
						name: '效率',
						type: 'line',
						itemStyle: {
							color: '#14F690'
						},
						areaStyle: {
							color: new this_.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: '#14F690'
							}, {
								offset: 0.5,
								color: 'rgba(20, 246, 144, 0.3)',
							}, {
								offset: 1,
								color: 'rgba(20, 246, 144, 0.1)',
							}])
						},
						data: this_.effectivenessArray
					}]
				};
				this_.incomeOption = {
					color: ['#f26813'],
					tooltip: {
						trigger: 'axis',
						formatter: function(params) {
							var relVal = '时间：' + new Date().Format("MM月") + params[0].value[0] + '日' + "<br/>";
							relVal = relVal + '收益：' + (params[0].value[1] * 1).toFixed(1) + '元';
							return relVal;
						}
					},
					grid: {
						left: 35,
						right: 10,
						height: 220,
						top: 30,
					},
					xAxis: {
						axisLine: {
							lineStyle: {
								color: '#1A679B'
							}
						},
						boundaryGap: false,
						type: 'category',
						data: this_.timeDate
					},
					yAxis: {
						nameTextStyle: {
							fontFamily: "微软雅黑",
							color: '#CDF9FF',
							fontSize: 10
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#1A679B'],
								width: 1,
								type: 'solid'
							}
						},
						axisLine: {
							lineStyle: {
								color: '#1A679B',
								width: 1, //这里是为了突出显示加上的
							}
						},
						name: '收益(元)',
						type: 'value'
					},
					series: [{
						name: '收益',
						type: 'bar',
						itemStyle: {
							color: new this_.$echarts.graphic.LinearGradient(
								0, 0, 0, 1, [{
										offset: 0,
										color: '#3AFFEF'
									},
									{
										offset: 1,
										color: '#1453F6'
									}
								]
							)
						},
						emphasis: {
							itemStyle: {
								color: new this_.$echarts.graphic.LinearGradient(
									0, 0, 0, 1, [{
											offset: 0,
											color: '#3AFFEF'
										},
										{
											offset: 0.7,
											color: '#1453F6'
										},
										{
											offset: 1,
											color: '#3AFFEF'
										}
									]
								)
							}
						},
						data: this_.incomes
					}]
				};
				this_.electricalEnergyOption = {
					tooltip: {
						position: [0, 0],
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
							label: {
								color: '#f26813'
							}
						},
						formatter: function(params) {
							var relVal = '时间：' + new Date().Format("MM月") + params[0].value[0] + '日' + "<br/>";
							relVal = relVal + '放电：' + (params[0].value[1] * 1).toFixed(1) + 'kWh';
							return relVal;
						}
					},
					grid: {
						left: 35,
						right: 10,
						height: 220,
						top: 30,
					},
					xAxis: {
						axisLine: {
							lineStyle: {
								color: '#1A679B'
							}
						},
						boundaryGap: false,
						type: 'category',
						data: this_.timeDate
					},
					yAxis: {
						nameTextStyle: {
							fontFamily: "微软雅黑",
							color: '#CDF9FF',
							fontSize: 10
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#1A679B'],
								width: 1,
								type: 'solid'
							}
						},
						axisLine: {
							lineStyle: {
								color: '#1A679B',
								width: 1, //这里是为了突出显示加上的
							}
						},
						name: '电量(kWh)',
						type: 'value',
					},
					series: {
						name: '放电量',
						type: 'bar',
						stack: '放电量',
						itemStyle: {
							color: new this_.$echarts.graphic.LinearGradient(
								0, 0, 0, 1, [{
										offset: 0,
										color: '#14F690'
									},
									{
										offset: 1,
										color: '#14AAF6'
									}
								]
							)
						},
						emphasis: {
							itemStyle: {
								color: new this_.$echarts.graphic.LinearGradient(
									0, 0, 0, 1, [{
											offset: 0,
											color: '#14AAF6'
										},
										{
											offset: 0.7,
											color: '#14F690'
										},
										{
											offset: 1,
											color: '#14AAF6'
										}
									]
								)
							}
						},
						data: this_.dischargeElectricEnergy,
					}
				};
				this_.electricalEnergyChart = this_.$echarts.init(document.getElementById(electricalEnergyId));
				this_.electricalEnergyChart.setOption(this_.electricalEnergyOption);
				this_.incomeChart = this_.$echarts.init(document.getElementById(incomeId));
				this_.incomeChart.setOption(this_.incomeOption);
				setTimeout(() => {
					window.onresize = function() {
						this_.incomeChart.resize();
						this_.electricalEnergyChart.resize();
						//						this_.effectivenessChart.resize();
					}
				}, 500);
			},
			reflush(type) {
				var this_ = this;
				if(type == 1) {
					var newOp = this_.incomeChart.getOption();
					newOp.series[0].data = this_.incomes;
					this_.incomeChart.setOption(newOp);
					this_.incomeChart.resize();
				} else {
					var newOp = this_.electricalEnergyChart.getOption();
					newOp.series[0].data = this_.dischargeElectricEnergy;
					this_.electricalEnergyChart.setOption(newOp);
					this_.electricalEnergyChart.resize();

					//					newOp = this_.effectivenessChart.getOption();
					//					newOp.series[0].data = this_.effectivenessArray;
					//					this_.effectivenessChart.setOption(newOp);
					//					this_.effectivenessChart.resize();
				}
			},

			toggleLabel(zoom) {
				const isShow = zoom >= 9
				this.labels.map(label => {
					if (isShow) {
						label.setStyle({
							display: 'block'
						})
					}else{
						label.setStyle({
							display: 'none'
						})
					}
				})
			}
		},
		destroyed() {
			clearInterval(this.timer_1);
		}
	}
</script>
<style>
@import "../assets/css/antd.css";
@import "../assets/css/home.css";
@import "../assets/css/reset.css";
@import "../assets/css/global.css";
.home_map {
	width: 100%;
	height: 900px;
	overflow: hidden;
	position: relative;
	z-index: 0;
	background-color: rgb(243, 241, 236);
	color: rgb(0, 0, 0);
	text-align: left;
}

.BMap_cpyCtrl {
	display: none !important;
}

.anchorBL a img {
	display: none !important;
}
/*地图标题 infoWindow*/

.BMap_bubble_title {
	text-align: left !important;
	background: transparent !important;
	font-family: "微软雅黑 ";
	font-size: 16px !important;
	color: #CDF9FF !important;
	font-weight: 600 !important;
}
.BMap_bubble_content{
	margin-top: 10px !important;
	font-size: 13px; 
	color: rgb(215, 215, 220);
}
.BMap_pop .BMap_top {
	background: rgba(48, 65, 86, .8) !important;
	border: 0 !important;
}

.home_map .BMap_pop .BMap_center {
	width: 331px !important;
	border: 0 !important;
	background: rgba(48, 65, 86, .8) !important;
}

.BMap_pop .BMap_bottom {
	border: 0 !important;
	background: rgba(48, 65, 86, .8) !important;
}

.BMap_pop div:nth-child(3) {
	background: transparent !important;
}

.BMap_pop div:nth-child(3) div {
	border-radius: 7px;
	background: rgba(48, 65, 86, .8) !important;
	border: 0 !important;
}

.BMap_pop div:nth-child(1) {
	border-radius: 7px 0 0 0;
	background: transparent !important;
	border: 0 !important;
}

.BMap_pop div:nth-child(1) div {
	background: rgba(48, 65, 86, .8) !important;
}

.BMap_pop div:nth-child(5) {
	border-radius: 0 0 0 7px;
	background: transparent !important;
	border: 0 !important;
}

.BMap_pop div:nth-child(5) div {
	border-radius: 7px;
	background: rgba(48, 65, 86, .8) !important;
}

.BMap_pop div:nth-child(7) {
	background: transparent !important;
	left: 226px;
}

.BMap_pop div:nth-child(7) div {
	border-radius: 7px;
	background: rgba(48, 65, 86, .8) !important;
}

/*替换箭头*/
img[src="http://api0.map.bdimg.com/images/iw3.png"] {
    content: url('../assets/images/iw3.png');
}

img[src="https://api.map.baidu.com/images/iw3.png"] {
    opacity: 0.7;
    margin-top: -692px !important;
    filter: alpha(opacity=70);
    content: url('../assets/images/iw3.png');
}


.searchSuggest {
	color: #33c094;
	background: #174c68;
	border-radius: 3px;
	overflow: hidden;
	width: 100%;
	/* position: absolute; */
	/* top: 52px; */
	/* left: 0; */
	margin-top: 5px;
	height: 300px;
	font-size: 14px;
	line-height: 30px;
}
.searchSuggestInfo {
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
	color: #CDF9FF;
	border-bottom: hsl(200deg 48% 32%) 1px solid;
	
}
.searchSuggestList {
	height: 270px;
	overflow-y: auto;
}
.searchSuggestItem {
	cursor: pointer;
	padding: 0 10px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.searchSuggestItem:hover {
	background: #117779;
}


</style>